import { useContext, useEffect, useState } from 'react';
import { Card } from '@mui/material';
import VerseContext from '../../context/VerseContext';

function ClaimActionMovingAverages() {
  const { claimData } = useContext(VerseContext);

  const [averages, setAverages] = useState({
    '7day': 0,
    '30day': 0,
    '90day': 0,
  });

  useEffect(() => {
    if (claimData[0]) {
      const dailyClaimedActions = {};
      claimData[0].forEach((tx) => {
        const date = new Date(tx.timeStamp * 1000).toISOString().slice(0, 10);
        dailyClaimedActions[date] = (dailyClaimedActions[date] || 0) + 1;
      });

      const calculateMovingAverage = (period) => {
        const movingAverages = [];
        const dates = Object.keys(dailyClaimedActions).sort();
        for (let i = period - 1; i < dates.length; i++) {
          const sum = dates
            .slice(i - period + 1, i + 1)
            .reduce((acc, date) => acc + dailyClaimedActions[date], 0);
          movingAverages.push(sum / period);
        }
        return movingAverages[movingAverages.length - 1];
      };

      setAverages({
        '7day': calculateMovingAverage(7),
        '30day': calculateMovingAverage(30),
        '90day': calculateMovingAverage(90),
      });
    }
  }, [claimData]);

  return (
    <div className="coreStats">
      <Card className="7dayMovingAverage">
        <div className="coreStatLabel">
          <strong>
            <small>7-Day MA Claim Actions</small>
          </strong>
        </div>
        <h4 className="coreStatAmount">
          {averages['7day'].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </h4>
      </Card>
      <Card className="30dayMovingAverage">
        <div className="coreStatLabel">
          <strong>
            <small>30-Day MA Claim Actions</small>
          </strong>
        </div>
        <h4 className="coreStatAmount">
          {averages['30day'].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </h4>
      </Card>
      <Card className="90dayMovingAverage">
        <div className="coreStatLabel">
          <strong>
            <small>90-Day MA Claim Actions</small>
          </strong>
        </div>
        <h4 className="coreStatAmount">
          {averages['90day'].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </h4>
      </Card>
    </div>
  );
}

export default ClaimActionMovingAverages;
