import {useContext} from 'react'
import VerseContext from '../../context/VerseContext';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function EmissionsBarChart() {
    const {today, currentSaleASupply, saleASupply, currentSaleBSupply, saleBSupply, currentSupply, currentTeamSupply, totalSupply, currentEcosystemSupply, currentDevFundSupply, ecosystemSupply, devFundSupply, teamSupply} = useContext(VerseContext)

    const data = {
        labels: ['Sale A', 'Sale B', 'Ecosystem', 'Dev Fund', 'Team'],
        datasets: [
            {   
                label: '% of Current Supply',
                data: [(currentSaleASupply / currentSupply * 100).toFixed(1), (currentSaleBSupply / currentSupply * 100).toFixed(1), (currentEcosystemSupply / currentSupply * 100).toFixed(1), (currentDevFundSupply / currentSupply * 100).toFixed(1), (currentTeamSupply / currentSupply * 100).toFixed(1)],
                backgroundColor: ['#FD695F', '#B95DEC', '#F2E477', '#10C0FF', '#5FEEAB'],
                borderColor: ['#FD695F', '#B95DEC', '#F2E477', '#10C0FF', '#5FEEAB'],
            },
        ]
    }
    
    const options = {
        maintainAspectRatio: false,
    }

  return (

      <Bar data={data} options={options} />
  )
}

export default EmissionsBarChart
