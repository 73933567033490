import useAxios from '../../hooks/useAxios'
import { useContext } from 'react'
import VerseContext from '../../context/VerseContext'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function VerseClaimTotal() {

  // Fetch Context
  const { today, claimData, claimedVerse, daysSinceLaunch } = useContext(VerseContext)

  if (!claimData) {
    return <div>Loading...</div>
  }

  let graphData = []
  let totalGraphData = []

  const formatGraphData = async () => {
    claimData[0].map(value => {
      graphData.push({ x: (new Date(value.timeStamp * 1000)).toISOString().slice(0, 10), y: (value.value / 1000000000000000000) })
    })

    graphData = graphData.reduce((acc, curr) => {
      let item = acc.find(item => item.x === curr.x);

      if (item) {
        item.y += curr.y;
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);
    getTotals(graphData)
  }

  const getTotals = graphData => {
    graphData = graphData.reduce((acc, {x, y}) => {
      acc[x] = y + (acc[x] || 0);
      return acc;
    }, {});

    totalGraphData = Object.entries(graphData).map(([key, y], index) => ({
      x: key,
      y: y
    }));

    totalGraphData.forEach((item, index) => {
      if (index) {
         item.y += totalGraphData[index - 1].y;
      }
    })
  }

  claimData[0] && formatGraphData()

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        }
      },
      y: {
        type: 'logarithmic',
        beginAtZero: true,
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        }
      },
    },
  };
  

  const data = {
    labels: graphData.map(value => moment(value.x).format('MMM DD')),
    datasets: [
      {
        fill: true,
        label: `Total Claimed Verse`,
        data: totalGraphData.map(value => value.y),
        borderColor: '#2793ff',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 1.5,
      }
    ]
  }

  return (
    <Line options={options} data={data} />
  )
}

export default VerseClaimTotal
