import {fetchClaimData, fetchAddressTxData, fetchAddressTokenBalance } from '../api/etherscanCalls';
import { createContext, useEffect, useState } from 'react';


const VerseContext = createContext()

export const VerseProvider = ({ children }) => {


    // --------------------- State --------------------- //
    const [claimData, setClaimData] = useState([])
    const [claimedVerse, setClaimedVerse] = useState()
    const [sortedSums, setSortedSums] = useState([]);
    const [stakedVerse, setStakedVerse] = useState([]);
    const [stakeTransactions, setStakeTransactions] = useState([])
    const [stakes, setStakes] = useState([])
    const [activelyStakedAddresses, setActivelyStakedAddresses] = useState([])
    const [cumulativeStakeAddresses, setCumulativeStakeAddresses] = useState([])
    const [unstakes, setUnstakes] = useState([])
    


    // --------------------- Etherscan Data --------------------- //

    // Fetch Data on all claims
    const getClaimData = async () => {
        const fetchedData = await fetchClaimData();
        setClaimData([...claimData, fetchedData.result.map(tx => tx)])
    }

    // Stake Data
    const getStakedVerse = async () => {
      const fetchedData = await fetchAddressTokenBalance('0xd920556b0f3522bb1257923292a256f1e3023e07');
      const finalData = fetchedData / 1000000000000000000;
      setStakedVerse(finalData);
  };

    function getActiveStakers(stakeTransactions) {
        const targetAddress = "0xd920556b0f3522bb1257923292a256f1e3023e07";
      
        // Collect total amounts sent and received for each address
        const addressData = stakeTransactions.reduce((acc, transaction) => {
          const fromAddress = transaction.from.toLowerCase();
          const toAddress = transaction.to.toLowerCase();
          const value = Number(transaction.value) / 10 ** 18;
      
          if (fromAddress !== targetAddress && toAddress === targetAddress) {
            // Transaction is sending VERSE to the staking contract
            acc[fromAddress] = (acc[fromAddress] || 0) + value;
          } else if (fromAddress === targetAddress && toAddress !== targetAddress) {
            // Transaction is receiving VERSE from the staking contract
            acc[toAddress] = (acc[toAddress] || 0) - value;
          }
      
          return acc;
        }, {});
      
      
        // Filter addresses with positive net amount (sent more than received)
        const activeStakers = Object.entries(addressData)
          .filter(([address, netAmount]) => netAmount > 0)
          .map(([address, netAmount]) => ({ address, netAmount }));
      
        setActivelyStakedAddresses(activeStakers);
        return activeStakers;
      }
      
      const getCumulativeStakers = (stakedTransactions) => {
        const targetAddress = '0xd920556b0f3522bb1257923292a256f1e3023e07';
  
        const senders = [];
        
        stakedTransactions.forEach((transaction) => {
          if (transaction.to.toLowerCase() === targetAddress.toLowerCase()) {
            senders.push(transaction.from.toLowerCase());
          }
        });

        setCumulativeStakeAddresses(senders)
        
        return Array.from(senders);
      }


      const getStakeTransactions = async () => {
        let stakesArray = [];
        let unstakesArray = [];
    
        try {
            const fetchedData = await fetchAddressTxData('0xd920556b0f3522bb1257923292a256f1e3023e07');
            fetchedData.forEach(tx => {
                if (tx.to === '0xd920556b0f3522bb1257923292a256f1e3023e07') {
                    stakesArray.push(tx);
                } else if (tx.from === '0xd920556b0f3522bb1257923292a256f1e3023e07') {
                    unstakesArray.push(tx);
                }
            });
    
            setStakeTransactions(fetchedData);
            setStakes(stakesArray);
            setUnstakes(unstakesArray);
    
            if (fetchedData) {
                getActiveStakers(fetchedData);
                getCumulativeStakers(fetchedData);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
      
      useEffect(() => {
        getClaimData();
        getStakedVerse();
        getStakeTransactions();
      }, []);
      


    // Fetch addresses that have interracted with the Verse contract
    const getAddressData = async (address) => {
        const fetchedAddressData = await fetchAddressTxData(address);
    }

    // Get Claimer Address data
    useEffect(() => {
        getAddressData('0xe5ac5142bde69cfa722662d9c3e4c8111f60b8d5')
    })


    // Fetch amount of claimed Verse
    const fetchClaimAmount = async () => {
        let claimAddressBalance = await fetchAddressTokenBalance('0xe5ac5142bde69cfa722662d9c3e4c8111f60b8d5')
        setClaimedVerse(210000000000 - (claimAddressBalance / 1000000000000000000))
    }

    useEffect(() => {
        fetchClaimAmount()
    }, [])



    let claimArray = []
    const calculateClaimData = () => {
        const addressSums = {};
      
        for (let i = 0; i < claimData[0].length; i++) {
          claimArray.push({
            txNumber: i,
            timeStamp: claimData[0][i].timeStamp,
            claimDate: (new Date(claimData[0][i].timeStamp * 1000)).toISOString().slice(0, 10),
            claimedAddress: claimData[0][i].to,
            claimValue: claimData[0][i].value / 1000000000000000000,
          });
      
          const currentAddress = claimData[0][i].to;
          const currentValue = claimData[0][i].value / 1000000000000000000;
      
          if (addressSums.hasOwnProperty(currentAddress)) {
            addressSums[currentAddress] += currentValue;
          } else {
            addressSums[currentAddress] = currentValue;
          }
        }
      
        setSortedSums(
            Object.entries(addressSums)
              .map(([address, sum]) => ({ address, sum }))
              .sort((a, b) => b.sum - a.sum)
          );
      };
      
      
        useEffect(() => {
            if (claimData.length > 0) {
                calculateClaimData();
            }
        }, [claimData]);

          
    

    // --------------------- Core Token Information --------------------- //

    let today = new Date().toISOString().slice(0, 10)

    // Verse Launch
    const startDate = '2022-12-07';

    // Calculate Days Since Launch
    const diffInMs = new Date(today) - new Date(startDate)
    const daysSinceLaunch = diffInMs / (1000 * 60 * 60 * 24);


    // Vesting Periods
    let saleADays = 365
    let saleBDays = 547.501
    let devFundDays = 2555
    let teamDays = 1460
    let ecosystemDays = 2555

    // Allocations
    let saleASupply = 21000000000
    let saleBSupply = 12600000000
    let devFundSupply = 71400000000
    let teamSupply = 31500000000
    let ecosystemSupply = 73500000000

    // Current Emissions
    let currentSaleASupply = saleASupply * (daysSinceLaunch / saleADays)
    let currentSaleBSupply = saleBSupply * (daysSinceLaunch / saleBDays)
    let currentDevFundSupply = devFundSupply * (daysSinceLaunch / devFundDays)
    let currentTeamSupply = teamSupply * (daysSinceLaunch / teamDays)
    let currentEcosystemSupply = ecosystemSupply * (daysSinceLaunch / ecosystemDays)

    // Current Supply
    let currentSupply = currentSaleASupply + currentSaleBSupply + currentDevFundSupply + currentTeamSupply + currentEcosystemSupply

    // Total Supply
    let totalSupply = 210000000000

    let supplySchedule = []

    for (let i = 0; i < 2700; i++) {
        supplySchedule.push({
            day: i,
            saleAEmissions: (i < 365 ? 21000000000 * (i / 365) : 21000000000),
            saleBEmissions: (i < 547.501 ? 12600000000 * (i / 547.501) : 12600000000),
            ecosystemEmissions: (i < 2555 ? 73500000000 * (i / 2555) : 73500000000),
            devFundEmissions: (i < 2555 ? 71400000000 * (i / 2555) : 71400000000),
            teamEmissions: (i < 1460 ? 31500000000 * (i / 1460) : 31500000000),
            overallSupply: ((
                (i < 365 ? 21000000000 * (i / 365) : 21000000000) +
                (i < 547.501 ? 12600000000 * (i / 547.501) : 12600000000) +
                (i < 2555 ? 73500000000 * (i / 2555) : 73500000000) +
                (i < 2555 ? 71400000000 * (i / 2555) : 71400000000) +
                (i < 1460 ? 31500000000 * (i / 1460) : 31500000000)
            )),
            inflationRate: (i < 2555 ? (
                (i < 365 ? 21000000000 * (1 / 365) : 0) +
                (i < 547.501 ? 12600000000 * (1 / 547.501) : 0) +
                (i < 2555 ? 73500000000 * (1 / 2555) : 0) +
                (i < 2555 ? 71400000000 * (1 / 2555) : 0) +
                (i < 1460 ? 31500000000 * (1 / 1460) : 0)
            ) / (
                    (i < 365 ? 21000000000 * (i / 365) : 21000000000) +
                    (i < 547.501 ? 12600000000 * (i / 547.501) : 12600000000) +
                    (i < 2555 ? 73500000000 * (i / 2555) : 73500000000) +
                    (i < 2555 ? 71400000000 * (i / 2555) : 71400000000) +
                    (i < 1460 ? 31500000000 * (i / 1460) : 31500000000)
                ) * 100 : 0).toFixed(8),
        })
    }

    return <VerseContext.Provider value={{
        today,
        totalSupply,
        saleASupply,
        saleBSupply,
        ecosystemSupply,
        devFundSupply,
        teamSupply,
        currentSupply,
        currentSaleASupply,
        currentSaleBSupply,
        currentDevFundSupply,
        currentTeamSupply,
        currentEcosystemSupply,
        daysSinceLaunch,
        supplySchedule,
        claimData,
        claimArray,
        claimedVerse,
        stakedVerse,
        stakeTransactions,
        stakes,
        unstakes,
        activelyStakedAddresses,
        cumulativeStakeAddresses,
        sortedSums,
    }}>
        {children}
    </VerseContext.Provider>
}


export default VerseContext