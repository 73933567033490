import {useContext} from 'react'
import VerseContext from '../../context/VerseContext'
import {
    Chart as ChartJS,
    LogarithmicScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';
  
  ChartJS.register(
    LogarithmicScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

function InflationSchedule() {
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


    // Fetch Context
    const {supplySchedule} = useContext(VerseContext)
    
    const options = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            type: 'logarithmic',
          }
        }
    }

    const data = {
        labels: supplySchedule.map(value => value.day),
        datasets: [
            {
                fill: true,
                label: 'Inflation Rate Per Day',
                data: supplySchedule.map(value => value.inflationRate),
                borderColor: '#0085FF',
                backgroundColor: 'rgba(000, 000, 000, 000)',
                pointRadius: 1.5,
            },
        ]
    }

  return (
    <div className="supplyCurves">
        <Line style={{ height: '100%' }} options={options} data={data} />
    </div>
  )
}

export default InflationSchedule
