import { useContext } from 'react';
import { Card } from '@mui/material';
import VerseContext from '../../context/VerseContext';
import useAxios from '../../hooks/useAxios';

function StakeUserStats() {
  const { activelyStakedAddresses, cumulativeStakeAddresses } = useContext(VerseContext);

  // Price API Response
  const { response, error } = useAxios(`coins/verse-bitcoin/`);

function calculateTotalMedianAmount(activelyStakedAddresses) {
  const amounts = activelyStakedAddresses.map((item) => item.netAmount);
  const sortedAmounts = [...amounts].sort();

  if (sortedAmounts.length === 0) {
    return 0;
  }

  const middleIndex = Math.floor(sortedAmounts.length / 2);

  if (sortedAmounts.length % 2 === 0) {
    return (
      (sortedAmounts[middleIndex - 1] + sortedAmounts[middleIndex]) / 2
    ).toFixed(2);
  } else {
    return sortedAmounts[middleIndex].toFixed(2);
  }
}


  return (
    <div className="coreStats">
        <Card className="totalClaimedCard">
            <div className="coreStatLabel"><strong><small>Current Stakers</small></strong></div>
            <h4 className='coreStatAmount'>{activelyStakedAddresses.length}</h4>
        </Card>
        <Card className="percentageClaimedCard">
            <div className="coreStatLabel"><strong><small>Cumulative Stakers</small></strong></div>
            <h4 className='coreStatAmount'>{cumulativeStakeAddresses.length}</h4>
        </Card>
        <Card className="avdDailyClaimCard">
            <div className="coreStatLabel"><strong><small>Staked User Retention</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round((activelyStakedAddresses.length) / (cumulativeStakeAddresses.length) * 100)}%</h4>
        </Card>
        <Card className="avdDailyClaimCard">
            <div className="coreStatLabel"><strong><small>Median Stake Size</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round(calculateTotalMedianAmount(activelyStakedAddresses)).toLocaleString()}</h4>
        </Card>
        <Card className="inflationClaimCard">
            <div className="coreStatLabel"><strong><small>Median Stake Value</small></strong></div>
            <h4 className='coreStatAmount'>${response && Math.round((response.market_data.current_price.usd) * (calculateTotalMedianAmount(activelyStakedAddresses))).toLocaleString()}</h4>
        </Card>
    </div>
  )
}

export default StakeUserStats