import React, { useState, useContext, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import VerseContext from '../../context/VerseContext';
import { Link, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination } from '@mui/material';

function StakeLeaderboard() {
  const { activelyStakedAddresses } = useContext(VerseContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // API Response
  const { response, error } = useAxios(`coins/verse-bitcoin/`);

  useEffect(() => {
    setPage(0);
  }, [activelyStakedAddresses]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Sort activelyStakedAddresses by netAmount in descending order
  const sortedAddresses = activelyStakedAddresses.sort((a, b) => b.netAmount - a.netAmount);

  return (
    <Paper sx={{ marginTop: '60px' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell><strong>Leaderboard</strong></TableCell>
              <TableCell><strong>Staked VERSE</strong></TableCell>
              <TableCell><strong>USD Value</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAddresses
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.address}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>
                    <Link underline="hover" href={`https://etherscan.io/address/${row.address}#tokentxns`} target="_blank">
                      {row.address}
                    </Link>
                  </TableCell>
                  <TableCell>{row.netAmount.toLocaleString('en-US', { maximumFractionDigits: 0 })}</TableCell>
                  <TableCell>${response && Math.round(response.market_data.current_price.usd * row.netAmount).toLocaleString('en-US', { maximumFractionDigits: 0 })}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={sortedAddresses.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </TableContainer>
    </Paper>
  );
}

export default StakeLeaderboard;
