import { useContext, useEffect, useState } from "react";
import VerseContext from "../../context/VerseContext";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
} from "chart.js";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function NewStakersOverTime() {
  const { stakeTransactions } = useContext(VerseContext);
  const [graphData, setGraphData] = useState([]);
  const [movingAverages, setMovingAverages] = useState({
    "7day": [],
    "30day": [],
    "90day": [],
  });

  useEffect(() => {
    if (stakeTransactions) {
      formatGraphData();
    }
  }, [stakeTransactions]);

  const formatGraphData = () => {
    const targetAddress = "0xd920556b0f3522bb1257923292a256f1e3023e07";
    const newStakersSet = new Set();
    
    // Ensure that stakeTransactions is not empty
    if (stakeTransactions && stakeTransactions.length > 0) {
      // Sort transactions by timestamp first
      stakeTransactions.sort((a, b) => a.timeStamp - b.timeStamp);
    
      const firstDate = moment.unix(stakeTransactions[0].timeStamp);
      const lastDate = moment.unix(stakeTransactions[stakeTransactions.length - 1].timeStamp);
    
      const dailyNewStakersData = {};
    
      // Initialize dailyNewStakersData with 0 for each day
      for (let m = moment(firstDate); m.isSameOrBefore(lastDate); m.add(1, "days")) {
        const date = m.format("MMM DD YYYY");
        dailyNewStakersData[date] = 0;
      }
  
      stakeTransactions
        .filter(
          (transaction) =>
            transaction.to.toLowerCase() === targetAddress.toLowerCase()
        )
        .forEach((transaction) => {
          const date = moment.unix(transaction.timeStamp).format("MMM DD YYYY");
          const address = transaction.from.toLowerCase();
  
          if (!newStakersSet.has(address)) {
            newStakersSet.add(address);
            dailyNewStakersData[date]++;
          }
        });
  
      const graphData = Object.keys(dailyNewStakersData).map((date) => ({
        x: date,
        y: dailyNewStakersData[date],
      }));
  
      setGraphData(graphData);
      calculateMovingAverages(dailyNewStakersData);
    }
  };
  

  const calculateMovingAverages = (dailyData) => {
    const datesWithGapsFilled = {};
    let dates = Object.keys(dailyData);
    dates = dates.map(date => moment(date, "MMM DD YYYY")).sort((a,b) => a - b).map(date => date.format("MMM DD YYYY"));
    const startDate = moment(dates[0], "MMM DD YYYY");
    const today = moment();

    for (
      let m = moment(startDate);
      m.isSameOrBefore(today, "days");
      m.add(1, "days")
    ) {
      const date = m.format("MMM DD YYYY");
      datesWithGapsFilled[date] = dailyData[date] || 0;
    }    

    const calculateMovingAverage = (period) => {
      const movingAverages = [];
      let dates = Object.keys(datesWithGapsFilled);
      dates = dates.map(date => moment(date, "MMM DD YYYY")).sort((a,b) => a - b).map(date => date.format("MMM DD YYYY"));

      for (let i = period - 1; i < dates.length; i++) {
        const sum = dates
          .slice(i - period + 1, i + 1)
          .reduce((acc, date) => acc + datesWithGapsFilled[date], 0);

        movingAverages.push({ x: dates[i], y: sum / period });
      }

      return movingAverages;
    };

    setMovingAverages({
      "7day": calculateMovingAverage(7),
      "30day": calculateMovingAverage(30),
      "90day": calculateMovingAverage(90),
    });
  };


  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        time: {
          parser: "MMM DD YYYY",
          unit: "day",
        },
      },
    },
  };

  const data = {
    datasets: [
      {
        type: "bar",
        label: "New Stakers",
        data: graphData,
        backgroundColor: "#71B37C",
      },
      {
        type: "line",
        label: "7-Day Moving Average",
        data: movingAverages["7day"],
        borderColor: "#ff7f50",
        fill: false,
        pointRadius: 0,
      },
      {
        type: "line",
        label: "30-Day Moving Average",
        data: movingAverages["30day"],
        borderColor: "#1e90ff",
        fill: false,
        pointRadius: 0,
      },
      {
        type: "line",
        label: "90-Day Moving Average",
        data: movingAverages["90day"],
        borderColor: "#9370db",
        fill: false,
        pointRadius: 0,
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

export default NewStakersOverTime;
