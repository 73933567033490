import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// import SwipeableViews from 'react-swipeable-views';
import {useState} from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function Navbar() {
  const [value, setValue] = useState('main');
  const url = useNavigate()

  const tabHandler = (urlParam, tab) => {
    setValue(tab)
    url(urlParam)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '30px', backgroundColor: '#0085FF', color: '#fff' }}>
      <Tabs
        value={value}
        textColor="inherit"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value="main" label="Verse Tokenomics" onClick={() => tabHandler('/', 'main')}/>
        <Tab value="claim-data" label="Claim Metrics" onClick={() => tabHandler('/claim-data', 'claim-data')} />
        <Tab value="stake-data" label="Stake Metrics" onClick={() => tabHandler('/stake-data', 'stake-data')} />
      </Tabs>
    </Box>
  )
}

export default Navbar