import {useContext} from 'react'
import { Card } from '@mui/material';
import VerseContext from '../../context/VerseContext'
import useAxios from '../../hooks/useAxios'

function CoreClaimStats() {

  const {claimData, claimedVerse, daysSinceLaunch} = useContext(VerseContext)

    let currentUnixDay = Math.floor(new Date().getTime() / 1000)
    let yesterday = (currentUnixDay - 86400)

    let dailyClaims = []
    let dailyClaimed = 0;

    claimData[0] && claimData[0].map(tx => {
        if(tx.timeStamp > yesterday ){
            dailyClaims.push(tx)
        }
    })

    dailyClaims.forEach(claim => dailyClaimed += (claim.value / 1000000000000000000))

  return (
    <div className="coreStats">
        <Card className="totalClaimedCard">
            <div className="coreStatLabel"><strong><small>Total VERSE Claimed</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round(claimedVerse).toLocaleString()}</h4>
        </Card>
        <Card className="percentageClaimedCard">
            <div className="coreStatLabel"><strong><small>VERSE Claimed in past 24hrs</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round(dailyClaimed).toLocaleString()}</h4>
        </Card>
        <Card className="percentageClaimedCard">
            <div className="coreStatLabel"><strong><small>24hr Claim Actions</small></strong></div>
            <h4 className='coreStatAmount'>{dailyClaims.length}</h4>
        </Card>
        <Card className="inflationClaimCard">
            <div className="coreStatLabel"><strong><small>24hr Claim Inflation</small></strong></div>
            <h4 className='coreStatAmount'>{(dailyClaimed / claimedVerse * 100).toFixed(2)}%</h4>
        </Card>
        <Card className="avdDailyClaimCard">
            <div className="coreStatLabel"><strong><small>Avg. VERSE Claimed Per Day</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round(claimedVerse / daysSinceLaunch).toLocaleString()}</h4>
        </Card>
    </div>
  )
}

export default CoreClaimStats