import { useContext, useEffect, useState } from 'react'
import VerseContext from '../../context/VerseContext'
import { Line } from 'react-chartjs-2';
import { 
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function VerseStakeTotal() {

  const { stakeTransactions } = useContext(VerseContext);
  const [graphData, setGraphData] = useState([]);
  const [totalGraphData, setTotalGraphData] = useState([]);

  useEffect(() => {
    if (stakeTransactions) {
      formatGraphData();
    }
  }, [stakeTransactions]);

  const formatGraphData = async () => {
    let formattedData = stakeTransactions
      .filter(value => new Date(Number(value.timeStamp) * 1000) >= new Date("2023-04-12"))
      .map(value => {
        const direction = value.from.toLowerCase() === '0xd920556b0f3522bb1257923292a256f1e3023e07'.toLowerCase() ? -1 : 1; // if from the contract, direction is -1, otherwise 1
        return { x: (new Date(Number(value.timeStamp) * 1000)).toISOString().slice(0, 10), y: direction * Number(value.value) / Math.pow(10, 18) } // divided by 10^18
      })
  
    formattedData = formattedData.reduce((acc, curr) => {
      let item = acc.find(item => item.x === curr.x);
  
      if (item) {
        item.y += curr.y;
      } else {
        acc.push(curr);
      }
  
      return acc;
    }, []);
  
    setGraphData(formattedData);
    getTotals(formattedData);
  }
  

  const getTotals = data => {
    let totals = data.reduce((acc, { x, y }) => {
      acc[x] = y + (acc[x] || 0);
      return acc;
    }, {});
  
    let formattedTotals = Object.entries(totals).map(([key, y], index) => ({
      x: key,
      y: y
    }));
  
    formattedTotals.forEach((item, index) => {
      if (index) {
        item.y += formattedTotals[index - 1].y;
      }
    });
  
    setTotalGraphData(formattedTotals);
  };
  
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        type: 'linear',
        // min: 0,
        // max: 700000000,
        ticks: {
          // stepSize: 10, 
          callback: function(value, index, values) {
            return value.toFixed(2);
          }
        },
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      }
    }
  }
  

  const data = {
    labels: graphData.map(value => moment(value.x).format('MMM DD')),
    datasets: [
      {
        fill: true,
        label: `Staking Contract Balance`,
        data: totalGraphData.map(value => value.y),
        borderColor: '#2793ff',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 1.5,
      }
    ]
  }

  return (
    <Line options={options} data={data} />
  )
}

export default VerseStakeTotal
