import { useContext } from 'react';
import { Card } from '@mui/material';
import VerseContext from '../../context/VerseContext';
import useAxios from '../../hooks/useAxios';

function CoreStakeStats() {
  const { stakedVerse, stakeTransactions, stakes, unstakes, daysSinceLaunch } = useContext(VerseContext);

  // API Response
  const { response, error } = useAxios(`coins/verse-bitcoin/`);

  if (error) {
    // handle error
  }

  let currentUnixDay = Math.floor(new Date().getTime() / 1000);
  let yesterday = (currentUnixDay - 86400);

  let dailyStakes = stakes && stakes.filter(tx => tx.timeStamp > yesterday);
  let dailyStaked = dailyStakes.reduce((sum, stake) => sum + (stake.value / 1000000000000000000), 0);

  let dailyUnstakes = unstakes && unstakes.filter(tx => tx.timeStamp > yesterday);
  let dailyUnstaked = dailyUnstakes.reduce((sum, unstake) => sum + (unstake.value / 1000000000000000000), 0);


  return (
    <div className="coreStats">
        <Card className="totalClaimedCard">
            <div className="coreStatLabel"><strong><small>Current Staked Verse</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round(stakedVerse).toLocaleString()}</h4>
        </Card>
        <Card className="percentageClaimedCard">
            <div className="coreStatLabel"><strong><small>Staked Verse TVL</small></strong></div>
            <h4 className='coreStatAmount'>$ {response && Math.round(response.market_data.current_price.usd * stakedVerse).toLocaleString()}</h4>
        </Card>
        <Card className="avdDailyClaimCard">
            <div className="coreStatLabel"><strong><small>24hr VERSE Staked</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round(dailyStaked).toLocaleString()}</h4>
        </Card>
        <Card className="avdDailyClaimCard">
            <div className="coreStatLabel"><strong><small>24hr VERSE Unstaked</small></strong></div>
            <h4 className='coreStatAmount'>{Math.round(dailyUnstaked).toLocaleString()}</h4>
        </Card>
        <Card className="inflationClaimCard">
            <div className="coreStatLabel"><strong><small>24hr Stake Volume</small></strong></div>
            <h4 className='coreStatAmount'>$ {response && Math.round(response.market_data.current_price.usd * Math.round(dailyStaked))}</h4>
        </Card>
        <Card className="inflationClaimCard">
            <div className="coreStatLabel"><strong><small>24hr Unstake Volume</small></strong></div>
            <h4 className='coreStatAmount'>$ {response && Math.round(response.market_data.current_price.usd * Math.round(dailyUnstaked))}</h4>
        </Card>

    </div>
  )
}

export default CoreStakeStats