import { useContext, useEffect, useState } from "react";
import VerseContext from "../../context/VerseContext";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function UniqueStakersOverTime() {
  const { stakeTransactions } = useContext(VerseContext);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (stakeTransactions) {
      formatGraphData();
    }
  }, [stakeTransactions]);

  const formatGraphData = () => {
    const targetAddress = "0xd920556b0f3522bb1257923292a256f1e3023e07";
    const filteredTransactions = stakeTransactions.filter(
      (transaction) =>
        transaction.to.toLowerCase() === targetAddress.toLowerCase()
    );
    const transactionData = filteredTransactions.map((transaction) => ({
      date: moment.unix(transaction.timeStamp).format("MMM DD"),
      address: transaction.from.toLowerCase(),
    }));
    const groupedData = groupTransactionsByDate(transactionData);
    const cumulativeData = calculateCumulativeCount(groupedData);
    const formattedData = formatChartData(cumulativeData);
    setGraphData(formattedData);
  };

  const groupTransactionsByDate = (transactionData) => {
    return transactionData.reduce((acc, { date, address }) => {
      if (!acc[date]) {
        acc[date] = new Set();
      }
      acc[date].add(address);
      return acc;
    }, {});
  };

  const calculateCumulativeCount = (groupedData) => {
    const dates = Object.keys(groupedData).sort();
    return dates.reduce((acc, date) => {
      const previousCount = acc.length > 0 ? acc[acc.length - 1].y : 0;
      const uniqueCount = groupedData[date].size;
      acc.push({ x: date, y: previousCount + uniqueCount });
      return acc;
    }, []);
  };

  const formatChartData = (data) => {
    return data.map(({ x, y }) => ({
      x,
      y,
    }));
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        // fill: true,
        type: "linear",
        beginAtZero: true,
        title: {
          display: true,
          text: "Cumulative Unique Stakers",
        },
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      }
    },
  };

  const data = {
    labels: graphData.map((value) => moment(value.x).format("MMM DD")),
    datasets: [
      {
        type: "line",
        fill: true,
        label: "Cumulative Unique Stakers",
        data: graphData,
        borderColor: '#00e591',
        backgroundColor: 'rgba(0, 255, 161, 0.5)',
        pointRadius: 1.5,
      },
    ],
  };

  return <Line options={options} data={data} />;
}

export default UniqueStakersOverTime;
