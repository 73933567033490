import CoreClaimStats from '../components/claim-components/CoreClaimStats';
import VerseClaimedToDate from '../components/claim-components/VerseClaimedToDate';
import VerseClaimTotal from '../components/claim-components/VerseClaimTotal';
import ClaimMovingAverages from '../components/claim-components/ClaimMovingAverages';
import ClaimMovingAveragesGraph from '../components/claim-components/ClaimMovingAveragesGraph';
import CoreClaimActions from '../components/claim-components/CoreClaimActions'
import ClaimActionsMAGraph from '../components/claim-components/ClaimActionsMAGraph'


function ClaimData() {
    
  return (
    <div className='mainContainer'>
      <div className="coreClaimStats">
        <CoreClaimStats />
      </div>
      <div className="coreClaimGraphs">
        <div className="claimInflationGraph"><VerseClaimedToDate /></div>
        <div className="claimToDateGraph"><VerseClaimTotal /></div>
      </div>
      <div className="averagesAndUnique">
        <div className="averageClaimsSection">
          <div className="averageClaimsCoreStats">
              <ClaimMovingAverages />
          </div>
          <div className="averageClaimsGraph">
              <ClaimMovingAveragesGraph />
          </div>
        </div>
        <div className="averageClaimsSection">
          <div className="averageClaimsCoreStats">
              <CoreClaimActions />
          </div>
          <div className="averageClaimsGraph">
              <ClaimActionsMAGraph />
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default ClaimData