import useAxios from '../../hooks/useAxios'
import { useContext, useEffect, useState } from 'react'
import VerseContext from '../../context/VerseContext'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function StakesUnstakesChart() {

  // Fetch Context
  const { stakeTransactions } = useContext(VerseContext)
  const [dailyStaked, setDailyStaked] = useState({})
  const [dailyUnstaked, setDailyUnStaked] = useState({})

  useEffect(() => {
    const staked = {}
    const unstaked = {}

    stakeTransactions.forEach(tx => {
      const date = moment.unix(tx.timeStamp).format('YYYY-MM-DD')
      const value = Number(tx.value) / Math.pow(10, Number(tx.tokenDecimal))
      
      if (tx.to.toLowerCase() === '0xd920556b0f3522bb1257923292a256f1e3023e07') {
        staked[date] = (staked[date] || 0) + value
      }
      
      if (tx.from.toLowerCase() === '0xd920556b0f3522bb1257923292a256f1e3023e07') {
        unstaked[date] = (unstaked[date] || 0) + value
      }
    })

    setDailyStaked(staked)
    setDailyUnStaked(unstaked)
  }, [stakeTransactions])

  const startDate = moment('2023-04-19');
  const labels = Object.keys(dailyStaked).filter(label => moment(label, 'YYYY-MM-DD').isSameOrAfter(startDate)).sort();
  const data = labels.map(label => dailyStaked[label] - (dailyUnstaked[label] || 0))
  const backgroundColor = data.map(value => value >= 0 ? 'rgba(53, 162, 235, 0.6)' : 'rgba(235, 53, 53, 0.6)')

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        time: {
          unit: 'day',
          min: '2023-04-19',
          displayFormats: {
            day: 'MMM D'
          }
        },
        title: {
          display: true,
          text: 'Date'
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Net Verse Staked'
        },
      },
    }
  }
  
  const chartData = {
    labels: labels,
    datasets: [
      {
        type: 'bar',
        label: `Net Staked Per Day`,
        data: data,
        backgroundColor: backgroundColor,
        borderWidth: 1,
        yAxisID: 'y',
      }
    ]
  }
    
  return (
    <Bar options={options} data={chartData} />
  )
}

export default StakesUnstakesChart
