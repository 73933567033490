import { VerseProvider } from './context/VerseContext';
import { Analytics } from '@vercel/analytics/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Main from './pages/Main'
import StakeData from './pages/StakeData'
import ClaimData from './pages/ClaimData'
import ROI from './pages/ROI'

function App() {
  return (
    <VerseProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Main />}></Route>
          <Route path='/stake-data' element={<StakeData />}></Route>
          <Route path='/claim-data' element={<ClaimData />}></Route>
          <Route path='/roi' element={<ROI />}></Route>
        </Routes>
        <Analytics />
      </Router>
    </VerseProvider>
  );
}

export default App;
