import useAxios from '../../hooks/useAxios'
import {useContext} from 'react'
import VerseContext from '../../context/VerseContext'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

function PriceHistoryChart() {

    // Fetch Context
    const {daysSinceLaunch} = useContext(VerseContext)

    // API Request
    const { response } = useAxios(`coins/verse-bitcoin/market_chart?vs_currency=usd&days=${daysSinceLaunch}`)
    
    if(!response) {
        return <div>Loading...</div>
    }

    const coinChartData = response.prices.map(value => ({ x: value[0], y: value[1] }))
    
    const options = {
        // responsive: true
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: false
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
      
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += '$' + context.parsed.y.toFixed(8).replace(/\d(?=(\d{3})+\.)/g, '$&,'); // format value with commas and 8 decimal places
                }
                return label;
              }
            }
          }
        }
    }

    const data = {
        labels: coinChartData.map(value => moment(value.x).format('MMM DD, HH:00')),
        datasets: [
            {
                fill: true,
                label: 'Verse Price',
                data: coinChartData.map(value => value.y),
                borderColor: '#00e591',
                backgroundColor: 'rgba(0, 255, 161, 0.5)',
                pointRadius: 1.5,
            }
        ]
    }

  return (
    <Line options={options} data={data} />
  )
}

export default PriceHistoryChart
