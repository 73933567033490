import {useContext} from 'react'
import { Card } from '@mui/material';
import VerseContext from '../../context/VerseContext'
import useAxios from '../../hooks/useAxios'

function CoreStats() {

  const {currentSupply} = useContext(VerseContext)

  // API Response
  const { response } = useAxios(`coins/verse-bitcoin/`)
  

  return (
    <div className="coreStats">
        <Card className="currentPrice">
          <div className="coreStatLabel"><strong><small>Current Price</small></strong></div>
          <h4 className='coreStatAmount'>${response && response.market_data.current_price.usd}</h4>
        </Card>
        <Card className="currentVolume">
          <div className="coreStatLabel"><strong><small>24hr Volume</small></strong></div>
          <h4 className='coreStatAmount'>${response && response.market_data.total_volume.usd.toLocaleString()}</h4>
        </Card>
        <Card className="marketCap">
          <div className="coreStatLabel"><strong><small>Marketcap</small></strong></div>
          <h4 className='coreStatAmount'>${response && (response.market_data.market_cap.usd).toLocaleString()}</h4>
        </Card>
        <Card className="fdvMarketCap">
          <div className="coreStatLabel"><strong><small>FDV Marketcap</small></strong></div>
          <h4 className='coreStatAmount'>${response && response.market_data.fully_diluted_valuation.usd.toLocaleString()}</h4>
        </Card>
        <Card className="currentSupply">
          <div className="coreStatLabel"><strong><small>Circulating Supply</small></strong></div>
          <h4 className='coreStatAmount'>{response && response.market_data.circulating_supply.toLocaleString()}</h4>
        </Card>
        <Card className="currentSupply">
          <div className="coreStatLabel"><strong><small>Unlocked Supply</small></strong></div>
          <h4 className='coreStatAmount'>{Math.round(currentSupply).toLocaleString()}</h4>
        </Card>
        <Card className="totalSupply">
          <div className="coreStatLabel"><strong><small>Total Supply</small></strong></div>
          <h4 className='coreStatAmount'>210,000,000,000</h4>
        </Card>
    </div>
  )
}

export default CoreStats