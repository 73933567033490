
import axios from 'axios';

// Fetch all claims
export const fetchClaimData = async () => {
    try {
        const { data } = await axios.request(`https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=0x249ca82617ec3dfb2589c4c17ab7ec9765350a18&address=0xe5ac5142bde69cfa722662d9c3e4c8111f60b8d5&offset=1000&startblock=16131197&sort=asc&apikey=${process.env.REACT_APP_ETHERSCAN_KEY}`);
        return data;
    } catch (errors) {
        console.log(errors);
    }
}

// Fetch Verse transactions by address
export const fetchAddressTxData = async (address) => {
    try {
        const { data } = await axios.request(`https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=0x249ca82617ec3dfb2589c4c17ab7ec9765350a18&address=${address}&&offset=1000&startblock=0&endblock=27025780&sort=asc&apikey=${process.env.REACT_APP_ETHERSCAN_KEY}`);
        return data.result;
    } catch (errors) {
        console.log(errors);
    }
}


// Fetch Token Balance for address
export const fetchAddressTokenBalance = async (address) => {
    try {
        const { data } = await axios.request(`https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=0x249ca82617ec3dfb2589c4c17ab7ec9765350a18&address=${address}&tag=latest&apikey=${process.env.REACT_APP_ETHERSCAN_KEY}`);
        return data.result;
    } catch (errors) {
        console.log(errors);
    }
}

// Fetch Token Transfer Events' by Address
export const fetchAddressTokenTransfers = async (address) => {
    try {
        const { data } = await axios.request(`https://api.etherscan.io/api
        ?module=account
        &action=tokentx
        &contractaddress=0x249cA82617eC3DfB2589c4c17ab7EC9765350a18
        &address=${address}
        &sort=asc
        &apikey=${process.env.REACT_APP_ETHERSCAN_KEY}`);
        console.log(data)
        return data
    } catch (errors) {
        console.log(errors);
    }
}