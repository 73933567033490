import { useContext, useEffect, useState } from 'react';
import { Card } from '@mui/material';
import VerseContext from '../../context/VerseContext';
import moment from 'moment';

function CoreStakeActions() {
  const { stakeTransactions } = useContext(VerseContext);
  const [dailyStakers, setDailyStakers] = useState([]);
  const [sevenDayMA, setSevenDayMA] = useState(0);
  const [thirtyDayMA, setThirtyDayMA] = useState(0);
  const [ninetyDayMA, setNinetyDayMA] = useState(0);

  useEffect(() => {
    if (stakeTransactions && stakeTransactions.length > 0) {
        const targetAddress = "0xd920556b0f3522bb1257923292a256f1e3023e07";
        let newStakersSet = new Set();
        let dailyNewStakersData = {};

        stakeTransactions
            .filter(transaction => transaction.to.toLowerCase() === targetAddress.toLowerCase())
            .forEach(transaction => {
                const date = moment.unix(transaction.timeStamp).format('MMM DD');
                const address = transaction.from.toLowerCase();

                if (!newStakersSet.has(address)) {
                    newStakersSet.add(address);
                    dailyNewStakersData[date] = (dailyNewStakersData[date] || 0) + 1;
                }
            });

        const dailyStakersArray = Object.entries(dailyNewStakersData);
        setDailyStakers(dailyStakersArray);

        const calculateMovingAverage = (period) => {
            const movingAverages = [];
            const dates = dailyStakersArray.map(([date, _]) => date).sort();

            for (let i = period - 1; i < dates.length; i++) {
                const sum = dates.slice(i - period + 1, i + 1).reduce((acc, date) => acc + dailyNewStakersData[date], 0);
                movingAverages.push(sum / period);
            }

            return movingAverages;
        };

        const sevenDayMovingAverage = calculateMovingAverage(7).slice(-1)[0];
        const thirtyDayMovingAverage = calculateMovingAverage(30).slice(-1)[0];
        const ninetyDayMovingAverage = calculateMovingAverage(90).slice(-1)[0];


        setSevenDayMA(sevenDayMovingAverage ? sevenDayMovingAverage.toFixed(0) : 'N/A');
        setThirtyDayMA(thirtyDayMovingAverage ? thirtyDayMovingAverage.toFixed(0) : 'N/A');
        setNinetyDayMA(ninetyDayMovingAverage ? ninetyDayMovingAverage.toFixed(0) : 'N/A');
    }
  }, [stakeTransactions]);

  return (
    <div className="coreStats">
        <Card className="avgNewStakersPerDayCard">
            <div className="coreStatLabel"><strong><small>New Stakers 7D MA</small></strong></div>
            <h4 className='coreStatAmount'>{sevenDayMA}</h4>
        </Card>
        <Card className="avgNewStakersPerDayCard">
            <div className="coreStatLabel"><strong><small>New Stakers 30D MA</small></strong></div>
            <h4 className='coreStatAmount'>{thirtyDayMA}</h4>
        </Card>
        <Card className="avgNewStakersPerDayCard">
            <div className="coreStatLabel"><strong><small>New Stakers 90D MA</small></strong></div>
            <h4 className='coreStatAmount'>{ninetyDayMA}</h4>
        </Card>
    </div>
  )
}

export default CoreStakeActions
