import useAxios from '../../hooks/useAxios'
import {useContext} from 'react'
import VerseContext from '../../context/VerseContext'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

function PriceHistoryChart() {
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


    // Fetch Context
    const {daysSinceLaunch} = useContext(VerseContext)

    // API Request
    const { response } = useAxios(`coins/verse-bitcoin/market_chart?vs_currency=usd&days=${daysSinceLaunch}`)
    
    if(!response) {
        return <div>Loading...</div>
    }

    const coinChartData = response.market_caps.map(value => ({ x: value[0], y: value[1] }))
    
    const options = {
        // responsive: true
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: false
            }
          },
        }
    }

    const data = {
        labels: coinChartData.map(value => moment(value.x).format('MMM DD, HH:00')),
        datasets: [
            {
                fill: true,
                label: 'Verse Marketcap',
                data: coinChartData.map(value => value.y),
                borderColor: '#2793ff',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                pointRadius: 1.5,
            }
        ]
    }

  return (
    <Line options={options} data={data} />
  )
}

export default PriceHistoryChart
