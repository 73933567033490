import {useContext} from 'react'
import VerseContext from '../../context/VerseContext';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { borderColor } from '@mui/system';
ChartJS.register(ArcElement, Tooltip, Legend);


function DoughnutSupplyChart() {
    const {today, currentSaleASupply, saleASupply, currentSaleBSupply, saleBSupply, currentSupply, currentTeamSupply, totalSupply, currentEcosystemSupply, currentDevFundSupply, ecosystemSupply, devFundSupply, teamSupply} = useContext(VerseContext)

    const data = {
        labels: ['Sale A', 'Sale B', 'Ecosystem', 'Dev', 'Team'],
        datasets: [
            {   
                label: '% of Current Supply',
                data: [(currentSaleASupply / currentSupply * 100).toFixed(1), (currentSaleBSupply / currentSupply * 100).toFixed(1), (currentEcosystemSupply / currentSupply * 100).toFixed(1), (currentDevFundSupply / currentSupply * 100).toFixed(1), (currentTeamSupply / currentSupply * 100).toFixed(1)],
                backgroundColor: ['#FD695F', '#B95DEC', '#F2E477', '#10C0FF', '#5FEEAB'],
                borderColor: ['#FD695F', '#B95DEC', '#F2E477', '#10C0FF', '#5FEEAB'],
            },
        ]
    }

    const options = {}

  return (
    <div>
        <Doughnut data={data} options={options} />
    </div>
    
  )
}

export default DoughnutSupplyChart