import {useContext} from 'react'
import { Card } from '@mui/material';
import VerseContext from '../../context/VerseContext'

function EmissionStats() {
    const {currentSaleASupply, saleASupply, currentSaleBSupply, saleBSupply, currentSupply, currentTeamSupply, totalSupply, currentEcosystemSupply, currentDevFundSupply, ecosystemSupply, devFundSupply, teamSupply} = useContext(VerseContext)
  return (
    <div className="emissionsStats">
            <Card className="saleASupplyStats">
                <div className='coreStatLabel'><strong>Sale A</strong></div>
                <div className="innerCardDiv">
                    <small>Emissions are <strong>{(currentSaleASupply / saleASupply * 100).toFixed(2)}%</strong> complete</small>
                    <h4>{Math.round(currentSaleASupply).toLocaleString()} VERSE</h4>
                    <small>out of {Math.round(saleASupply).toLocaleString()} VERSE has unlocked</small>
                </div>
            </Card>
            <Card className="saleBSupplyStats">
                <div className='coreStatLabel'><strong>Sale B</strong></div>
                <div className="innerCardDiv">
                    <small>Emissions are <strong>{(currentSaleBSupply / saleBSupply * 100).toFixed(2)}%</strong> complete</small>
                    <h4>{Math.round(currentSaleBSupply).toLocaleString()} VERSE</h4>
                    <small>out of {Math.round(saleBSupply).toLocaleString()} VERSE has unlocked</small>
                </div>
            </Card>
            <Card className="ecosystemSupplyStats">
                <div className='coreStatLabel'><strong>Ecosystem</strong></div>
                <div className="innerCardDiv">
                    <small>Emissions are <strong>{(currentEcosystemSupply / ecosystemSupply * 100).toFixed(2)}%</strong> complete</small>
                    <h4>{Math.round(currentEcosystemSupply).toLocaleString()} VERSE</h4>
                    <small>out of {Math.round(ecosystemSupply).toLocaleString()} VERSE has unlocked</small>
                </div>
            </Card>
            <Card className="devFundSupplyStats">
                <div className='coreStatLabel'><strong>Development Fund</strong></div>
                <div className="innerCardDiv">
                    <small>Emissions are <strong>{(currentDevFundSupply / devFundSupply * 100).toFixed(2)}%</strong> complete</small>
                    <h4>{Math.round(currentDevFundSupply).toLocaleString()} VERSE</h4>
                    <small>out of {Math.round(devFundSupply).toLocaleString()} VERSE has unlocked</small>
                </div>
            </Card>
            <Card className="teamSupplyStats">
                <div className='coreStatLabel'><strong>Team Supply</strong></div>
                <div className="innerCardDiv">
                    <small>Emissions are <strong>{(currentTeamSupply / teamSupply * 100).toFixed(2)}%</strong> complete</small>
                    <h4>{Math.round(currentTeamSupply).toLocaleString()} VERSE</h4>
                    <small>out of {Math.round(teamSupply).toLocaleString()} VERSE has unlocked</small>
                </div>
            </Card>
    </div>
  )
}

export default EmissionStats
