import {useContext} from 'react'
import { Card } from '@mui/material';
import VerseContext from '../context/VerseContext'
import CoreStats from '../components/main-components/CoreStats'
import PriceHistoryChart from '../components/main-components/PriceHistoryChart'
import MarketcapHistoryChart from '../components/main-components/MarketcapHistoryChart'
import EmissionStats from '../components/main-components/EmissionStats'
import DoughtnutSupplyChart from '../components/main-components/DoughnutSupplyChart'
import EmissionsBarChart from '../components/main-components/EmissionsBarChart'
import SupplyCurves from '../components/main-components/SupplyCurves'
import InflationSchedule from '../components/main-components/InflationSchedule'

function Main() {
    const {today, daysSinceLaunch, currentSupply, totalSupply, supplySchedule} = useContext(VerseContext)
  return (
    <div className='mainContainer'>
        <CoreStats />
        <div className="priceChartContainer">
            <div className="priceGraph">
                <PriceHistoryChart />
                <MarketcapHistoryChart />
            </div>
        </div>
        <div className="mainSupplyHeaders">
            <div className="currentDistributions">
                <Card className="currenSupplyHeaders">
                    <div className='coreStatLabel'><strong>Current Supply Distributions</strong></div>
                    <div className="innerCardDiv">
                        <h1 className='supplyDoughnutHeader'>{Math.round(currentSupply).toLocaleString()} VERSE Unlocked</h1>
                        <p className='supplyDoughnutSubHeader'>{(currentSupply / totalSupply * 100).toFixed(2)}% of Total Supply as of {today}</p>
                    </div>
                </Card>
                <SupplyCurves />
            </div>
           <div className="currentInflationRates">
                <Card className="currenSupplyHeaders">
                        <div className='coreStatLabel'><strong>Current Daily Inflation Rate</strong></div>
                        <div className="innerCardDiv">
                            <h1 className='supplyDoughnutHeader'>{ (Number(supplySchedule[daysSinceLaunch].inflationRate)).toFixed(2) }% of current supply</h1>
                            <p className='supplyDoughnutSubHeader'>{Math.round((supplySchedule[daysSinceLaunch].inflationRate / 100) * currentSupply).toLocaleString()} VERSE unvested today</p>
                        </div>
                </Card>
                <InflationSchedule />
           </div>
        </div>
        <EmissionStats />
        <div className="supplyChartContainer">
            <div className="currentSupplyCharts">
                <div className="emissionsChart">
                    <div className="emissionsDoughnutGraph">
                        <DoughtnutSupplyChart />
                    </div>
                    <div className="emissionsBarChart">
                        <EmissionsBarChart />
                    </div>
                </div>
            </div>
        </div>
        <div className="supplyCurveGraphs">
        </div>
    </div>
  )
}

export default Main