import {useContext} from 'react'
import { Card } from '@mui/material';
import VerseContext from '../context/VerseContext'


function Main() {
    const {today, currentSupply, totalSupply} = useContext(VerseContext)
  return (
    <div className='mainContainer'>
        roi
    </div>
  )
}

export default Main