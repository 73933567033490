import {useContext} from 'react'
import { Card } from '@mui/material';
import VerseContext from '../context/VerseContext';
import CoreClaimStats from '../components/claim-components/CoreClaimStats';
import AverageClaimStats from '../components/claim-components/AverageClaimStats';
import VerseClaimedToDate from '../components/claim-components/VerseClaimedToDate';
import VerseClaimTotal from '../components/claim-components/VerseClaimTotal';
import ClaimAddressTable from '../components/claim-components/ClaimAddressTable'
import ClaimMovingAverages from '../components/claim-components/ClaimMovingAverages';
import ClaimMovingAveragesGraph from '../components/claim-components/ClaimMovingAveragesGraph';
import CoreStakeStats from '../components/stake-components/CoreStakeStats';
import StakesUnstakesChart from '../components/stake-components/StakesUnstakesChart';
import VerseStakeTotal from '../components/stake-components/VerseStakeTotal';
// import NetDailyStakeAverages from '../components/stake-components/NetDailyStakeAverages';
// import StakeMovingAverages from '../components/stake-components/StakeMovingAverages';
import ActiveStakersOverTime from '../components/stake-components/ActiveStakersOverTime';
import UniqueStakersOverTime from '../components/stake-components/UniqueStakersOverTime';
import StakeUserStats from '../components/stake-components/StakeUserStats';
import StakeLeaderboard from '../components/stake-components/StakeLeaderboard';
import NewStakersOverTime from '../components/stake-components/NewStakersOverTime';
import CoreStakeActions from '../components/stake-components/CoreStakeActions';

function StakeData() {
  const {today, daysSinceLaunch, currentSupply, totalSupply, supplySchedule, claimedVerse} = useContext(VerseContext)
    
  return (
    <div className='mainContainer'>
      <div className="coreClaimStats">
        <CoreStakeStats />
      </div>
      <div className="coreClaimGraphs">
        <div className="claimInflationGraph">
          <StakesUnstakesChart />
        </div>
      </div>
      <StakeUserStats />
      <div className="coreClaimGraphs">
      <div className="claimInflationGraph">
          <UniqueStakersOverTime />
        </div>
        <div className="claimInflationGraph">
        <VerseStakeTotal />
        </div>
      </div>
      <CoreStakeActions />
      <div className="claimInflationGraph">
          <NewStakersOverTime />
      </div>
      <div className="claimInflationGraph">
          <StakeLeaderboard />
      </div>
      {/* <AllStakesOverTime /> */}
    </div>
  )
}

export default StakeData