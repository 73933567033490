import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import VerseContext from '../../context/VerseContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function ClaimMovingAveragesGraph() {
  // Fetch Context
  const { today, claimData, claimedVerse, daysSinceLaunch } = useContext(
    VerseContext
  );

  const [graphData, setGraphData] = useState([]);
  const [movingAverages, setMovingAverages] = useState({
    '7day': [],
    '30day': [],
    '90day': [],
  });

  useEffect(() => {
    if (claimData[0]) {
      const dailyClaimedData = {};
      claimData[0].forEach((tx) => {
        const date = new Date(tx.timeStamp * 1000).toISOString().slice(0, 10);
        const value = tx.value / 1000000000000000000;
        dailyClaimedData[date] = (dailyClaimedData[date] || 0) + value;
      });

      const graphData = Object.keys(dailyClaimedData).map((date) => ({
        x: date,
        y: dailyClaimedData[date],
      }));

      setGraphData(graphData);

      const calculateMovingAverage = (period) => {
        const movingAverages = [];
        const dates = Object.keys(dailyClaimedData).sort();
        for (let i = period - 1; i < dates.length; i++) {
          const sum = dates
            .slice(i - period + 1, i + 1)
            .reduce((acc, date) => acc + dailyClaimedData[date], 0);
          movingAverages.push({ x: dates[i], y: sum / period });
        }
        return movingAverages;
      };

      setMovingAverages({
        '7day': calculateMovingAverage(7),
        '30day': calculateMovingAverage(30),
        '90day': calculateMovingAverage(90),
      });
    }
  }, [claimData]);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        }
      },
    },
  };
  
  

  const data = {
    labels: graphData.map((value) => moment(value.x).format('YYYY-MM-DD')),
    datasets: [
      {
        label: `7-Day Moving Average`,
        data: movingAverages['7day'],
        borderColor: '#ff7f50',
        fill: false,
        pointRadius: 0,
      },
      {
        label: `30-Day Moving Average`,
        data: movingAverages['30day'],
        borderColor: '#1e90ff',
        fill: false,
        pointRadius: 0,
      },
      {
        label: `90-Day Moving Average`,
        data: movingAverages['90day'],
        borderColor: '#9370db',
        fill: false,
        pointRadius: 0,
      },
    ],
  };

  return <Line options={options} data={data} />;
}

export default ClaimMovingAveragesGraph