import useAxios from '../../hooks/useAxios'
import {useContext} from 'react'
import VerseContext from '../../context/VerseContext'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import moment from 'moment/moment';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

function VerseClaimedToDate() {

    // Fetch Context
    const {today, claimData, claimedVerse, daysSinceLaunch} = useContext(VerseContext)
  

    let graphData = []
    
    const formatGraphData = async () => {
        claimData[0].map(value => {
            graphData.push({ x: (new Date(value.timeStamp * 1000)).toISOString().slice(0, 10), y: (value.value / 1000000000000000000) })
        })

        graphData = graphData.reduce((acc, curr) => {
            let item = acc.find(item => item.x === curr.x);
            item ? item.y += curr.y : acc.push(curr);
            return acc;
        }, []);
    }

    claimData[0] && formatGraphData()
    
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          grid: {
            drawBorder: false,
            drawOnChartArea: false,
            drawTicks: false,
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            drawBorder: false,
            drawOnChartArea: false,
            drawTicks: false,
          }
        },
      },
    };
    

    const data = {
        labels: graphData.map(value => moment(value.x).format('MMM DD')),
        datasets: [
            {
                fill: true,
                label: `Verse Claimed Each Day`,
                data: graphData.map(value => value.y),
                borderColor: '#00e591',
                backgroundColor: 'rgba(0, 255, 161, 0.5)',
                pointRadius: 1.5,
            }
        ]
    }

  return (
    <Line options={options} data={data} />
  )
}

export default VerseClaimedToDate
